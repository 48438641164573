/* #svg-map path { fill:#723989 }
    #svg-map text { fill:#fff; font:12px Arial-BoldMT, sans-serif; cursor:pointer }
    #svg-map  path:hover{ fill:#421d51 !important }
    #svg-map .circle { fill:#ee9338 }
    #svg-map path:hover .circle { fill:#003399 !important; cursor:pointer }

  
    .hover-container {
      position: relative;
    }

    .item-to-display {
      position: absolute;
      display:block;
      top: 50%;
      left: 50%;
      width: 300px;
      height: 400px;
      background: #000;
      opacity: 0;
      transition: opacity 0.5s;

    } */


.svg-map path {
  fill: #723989;
  stroke: #fff;
  stroke-width: 1;
  transition: 1s all;
}

.svg-map path:hover{
  fill: #341440;

}

