.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 8px;
  margin: 20px 0;
  font-size: 1.2rem;
  color: #555;
  text-align: center;
}

.file-upload.dragging {
  background-color: #eee;
}

.file-upload p {
  margin: 0;
}

.file-upload:hover {
  cursor: pointer;
}