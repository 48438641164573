img {
  max-width: 100%;
}

.MuiDataGrid-columnHeaders {
  color: #fff;
  background-color: #723989;
}


.MuiDataGrid-columnHeaders * {
  color: #fff;
}

.leaflet-container {
  width: 100%;
  height: 80vh;
  z-index: 1;
}

div[class="leaflet-marker-icon marker-icon leaflet-zoom-animated leaflet-interactive leaflet-marker-draggable leaflet-pm-draggable"] {
  display: none;
}

.muidatagrid-cell:focus {
  outline: 0
}